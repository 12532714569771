import dayjs from 'moment'; // moment is replace by day.js in webpack
import { UploadFileStatus } from 'antd/lib/upload/interface';

type TransformConfig = {
  hasMany?: string[],
  belongsTo?: string[],
  date?: string[],
  attachment?: string[],
};

export const fromGraphql = (obj: any, transforms: TransformConfig) => {
  const hasMany = (transforms.hasMany || []).reduce((transformed, key) => ({
    ...transformed,
    [key]: obj[key]?.map((r: any) => r.id) || [],
  }), {});

  const date = (transforms.date || []).reduce((transformed, key) => ({
    ...transformed,
    [key]: (obj[key]) ? dayjs(obj[key]) : undefined,
  }), {});

  const attachment = (transforms.attachment || []).reduce((transformed, key) => ({
    ...transformed,
    [key]:
      obj[key]
        ? [{
          uid: obj[key].id,
          name: obj[key].name,
          status: 'done' as UploadFileStatus,
          url: obj[key].url,
          size: 0,
          type: 'doc',
        }]
        : [],
  }), {});

  return {
    ...obj,
    ...hasMany,
    ...date,
    ...attachment,
  };
};

export const toGraphql = (obj: any, transforms: TransformConfig, mode: 'UPDATE' | 'CREATE') => {
  const disconnectRelation = (disconnect: boolean) => (
    ((disconnect) ? { disconnect } : {})
  );

  const belongsTo = (transforms.belongsTo || []).reduce((transformed, key) => ({
    ...transformed,
    [key]: (
      obj[key].id
        ? { connect: { id: obj[key].id } }
        : (disconnectRelation((mode === 'UPDATE')))
    ),
  }), {});

  const hasMany = (transforms.hasMany || []).reduce((transformed, key) => ({
    ...transformed,
    [key]: (
      obj[key]
        ? { [mode === 'UPDATE' ? 'set' : 'connect']: obj[key].map((id: number) => ({ id })) }
        : {}
    ),
  }), {});

  const date = (transforms.date || []).reduce((transformed, key) => ({
    ...transformed,
    [key]: (obj[key]) ? obj[key].toISOString() : null,
  }), {});

  return {
    ...obj,
    ...belongsTo,
    ...hasMany,
    ...date,
  };
};
